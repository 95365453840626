// import React, { useRef, useEffect } from 'react';
// import './network.css';
// import tennisicon from '../Assets/images2/teenyicons_tick-solid.png';
//  import ts2 from '../Assets/images2/ts2.png';
//  import ts3 from '../Assets/images2/ts3.png';
//  import ts4 from '../Assets/images2/ts4.png';
//  import ts5 from '../Assets/images2/ts5.png';
//  import ts6 from '../Assets/images2/ts6.png';
//  import ts7 from '../Assets/images2/ts7.png';
//  const Option = ({ networkName, networkIcon, isSelected, onSelect }) => {
//     return (
//         <div className="network-overlay-inner" onClick={() => onSelect({ name: networkName, icon: networkIcon })}>
//             <div className="networks-parent">
//                 <img className="networks-icon" alt="" src={networkIcon} />
//                 <div className={isSelected ? "ethereum selected" : "ethereum"}>{networkName}</div>
//             </div>
//             {isSelected && (
//                 <img className="teenyiconstick-solid" alt="" src={tennisicon} />
//             )}
//         </div>
//     );
// };


// const NetworkOverlay = ({ selectedNetwork, onSelectNetwork }) => {
//     const [isOpen, setIsOpen] = React.useState(false);
//     const dropdownRef = useRef(null);
//     const networkOverlayRef = useRef(null);

//     const toggleDropdown = () => {
//         setIsOpen(!isOpen);
//     };

//     useEffect(() => {
//         function handleClickOutside(event) {
//             if (
//                 isOpen &&
//                 networkOverlayRef.current &&
//                 !networkOverlayRef.current.contains(event.target)
//             ) {
//                 setIsOpen(false);
//             }
//         }

//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, [isOpen]);

//     const imageStyles = {
//                 height: '23px',
//                 width: '23px',
//             };

//     const options = [
//         { key: 1, networkName: 'Ethereum', networkIcon: './images/sprint.png' },
//         { key: 2, networkName: 'Arbitrum', networkIcon: ts2 },
//         { key: 3, networkName: 'Optimism', networkIcon: ts3 },
//         { key: 4, networkName: 'Polygon', networkIcon: ts4 },
//         { key: 5, networkName: 'BNB Chain', networkIcon: ts5 },
//         { key: 6, networkName: 'Avalanche', networkIcon: ts6 },
//         { key: 7, networkName: 'Celo', networkIcon: ts7 },
//         { key: 8, networkName: 'Base', networkIcon: 'https://www.baseprotocol.org/files/5f51b71bb4b4537bf94b57fc/5f51b71bb4b453b3364b584f_asset 3_2x.png' }
//     ].map(option => ({
//         ...option,
//         propName: option.networkName === selectedNetwork?.name
//     }));

//     return (
//         <>
//             <button
//                 id="selectedOptionBtn"
//                 ref={networkOverlayRef}
//                 type="button"
//                 className="btnGroupDrop1"
//                 onClick={toggleDropdown}
//             >
//                 {selectedNetwork?.icon && (
//                     <img src={selectedNetwork?.icon} className="ms-2 networks-icon" alt="" style={imageStyles} />
//                 )}
//                 <img
//                     src="/images/dropdown.png"
//                     className={`icons ${isOpen ? 'rotated' : ''}`}
//                     alt=""
//                     style={imageStyles}
//                 />
//                 {isOpen && (
//                     <div className="network-overlay" ref={dropdownRef}>
//                         {options.map((option, index) => (
//                             <Option
//                                 key={index}
//                                 networkName={option.networkName}
//                                 networkIcon={option.networkIcon}
//                                 isSelected={option.propName}
//                                 onSelect={onSelectNetwork}
//                             />
//                         ))}
//                     </div>
//                 )}
//             </button>
//         </>
//     );
// };

// export default NetworkOverlay;

// import React, { useRef, useEffect, useState } from 'react';
// import './network.css';
// import tennisicon from '../Assets/images2/teenyicons_tick-solid.png';
// import ts2 from '../Assets/images2/ts2.png';
// import ts3 from '../Assets/images2/ts3.png';
// import ts4 from '../Assets/images2/ts4.png';
// import ts5 from '../Assets/images2/ts5.png';
// import ts6 from '../Assets/images2/ts6.png';
// import ts7 from '../Assets/images2/ts7.png';
// import Web3 from 'web3';
// const NetworkOverlay = () => {
//     const [isOpen, setIsOpen] = useState(false);
//     const [selectedNetwork, setSelectedNetwork] = useState('');
//     const [walletNetwork, setWalletNetwork] = useState('');

//     const dropdownRef = useRef(null);
//     const networkOverlayRef = useRef(null);

//     const toggleDropdown = () => {
//         setIsOpen(!isOpen);
//     };

//     const handleNetworkSelection = async (chainId) => {
//         setSelectedNetwork(chainId);

//         // Create a Web3 instance
//         const web3 = new Web3(window.ethereum);

//         try {
//             await web3.currentProvider.request({
//                 method: 'wallet_switchEthereumChain',
//                 params: [{ chainId: chainId }],
//             });
//             console.log(`Switched wallet network to ${chainId}`);
//             setWalletNetwork(chainId);
//             window.location.reload();
//         } catch (error) {
//             console.error(error);
//         }
//     };

//     useEffect(() => {
//         function handleClickOutside(event) {
//             if (
//                 isOpen &&
//                 networkOverlayRef.current &&
//                 !networkOverlayRef.current.contains(event.target)
//             ) {
//                 setIsOpen(false);
//             }
//         }

//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, [isOpen]);

//     const options = [
//         { key: 1, networkName: 'Ethereum', chainId: '0x1', networkIcon: './images/sprint.png' }, // Example chainId for Ethereum
//         { key: 2, networkName: 'Arbitrum', chainId: '0xa4b1', networkIcon: ts2 },
//         { key: 3, networkName: 'Optimism', chainId: '0xa', networkIcon: ts3 },
//         { key: 4, networkName: 'Polygon', chainId: '0x13881', networkIcon: ts4 },
//         { key: 5, networkName: 'BNB Chain', chainId: '0x61', networkIcon: ts5 },
//         { key: 6, networkName: 'Avalanche', chainId: '0xa869', networkIcon: ts6 },
//         { key: 7, networkName: 'Celo', chainId: '0xa4ec', networkIcon: ts7 },
//         { key: 8, networkName: 'Base', chainId: '0x2105', networkIcon: 'https://www.baseprotocol.org/files/5f51b71bb4b4537bf94b57fc/5f51b71bb4b453b3364b584f_asset 3_2x.png' }
//     ];

//     return (
//         <>
//             <button
//                 id="selectedOptionBtn"
//                 ref={networkOverlayRef}
//                 type="button"
//                 className="btnGroupDrop1"
//                 onClick={toggleDropdown}
//             >

//                 <img src="./images/sprint.png" className="ms-2 networks-icon" alt="" />

//                 <img
//                     src="/images/dropdown.png"
//                     className={`icons ${isOpen ? 'rotated' : ''}`}
//                     alt=""
//                 />
//                 {isOpen && (
//                     <div className="network-overlay" ref={dropdownRef}>
//                         {options.map(option => (
//                             <div key={option.key} className="network-overlay-inner" onClick={() => handleNetworkSelection(option.chainId)}>
//                                 <div className="networks-parent">
//                                     <img className="networks-icon" alt="" src={option.networkIcon} />
//                                     <div className="ethereum">{option.networkName}</div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 )}
//             </button>
//         </>
//     );
// };

// export default NetworkOverlay;




import React, { useRef, useEffect } from 'react';
import './network.css';
import tennisicon from '../Assets/images2/teenyicons_tick-solid.png';
import ts2 from '../Assets/images2/ts2.png';
import ts3 from '../Assets/images2/ts3.png';
import ts4 from '../Assets/images2/ts4.png';
import ts5 from '../Assets/images2/ts5.png';
import ts6 from '../Assets/images2/ts6.png';
import ts7 from '../Assets/images2/ts7.png';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { getCurrentChainId, getCurrentProvider } from '../ContractAction/ContractDependency';
const Option = ({ networkName, networkIcon, isSelected, onSelect, onClick }) => {
    return (
        <div className="network-overlay-inner" onClick={onClick}>
            <div className="networks-parent">
                <img className="networks-icon" alt="" src={networkIcon} />
                <div className={isSelected ? "ethereum selected" : "ethereum"}>{networkName}</div>
            </div>
            {isSelected && (
                <img className="teenyiconstick-solid" alt="" src={tennisicon} />
            )}
        </div>
    );
};

const NetworkOverlay = ({ selectedNetwork, onSelectNetwork }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [currentChainIdIcon, setCurrentChainIdIcon] = React.useState(null);
    const [currentChainIdName, setCurrentChainIdName] = React.useState(null);

    const dropdownRef = useRef(null);
    const networkOverlayRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
console.log("isOpen",isOpen);
    const options = [
        { key: 1, networkName: 'Ethereum', networkIcon: './images/sprint.png', chainId: '0x1' },
        { key: 2, networkName: 'Arbitrum', networkIcon: ts2, chainId: '0xa4b1' },
        { key: 3, networkName: 'Optimism', networkIcon: ts3, chainId: '0xa' },
        { key: 4, networkName: 'Polygon', networkIcon: ts4, chainId: '0x013882' },
        { key: 5, networkName: 'BNB Chain', networkIcon: ts5, chainId: '0x61' },
        { key: 6, networkName: 'Avalanche', networkIcon: ts6, chainId: '0xa869' },
        { key: 7, networkName: 'Celo', networkIcon: ts7, chainId: '0xa4ec' },
        { key: 8, networkName: 'Base', networkIcon: 'https://cdn.discordapp.com/icons/1067165013397213286/700ef27c9d190b778ed106f29fafd32e.webp?size=160&quot', chainId: '0x2105' }
    ].map(option => ({
        ...option,
        propName: option.networkName === selectedNetwork?.name
    }));

    useEffect(() => {
        // const checkChainId = async () => {
        //     const chainId = await getCurrentChainId();
        //     const provider = await getCurrentProvider();
        //     const web3 = new Web3(provider);
        //     const chainIdHex = await web3.utils.toHex(chainId);
        //     const networkIcon = options.find(option => option.chainId === chainIdHex);
        //     setCurrentChainIdIcon(networkIcon?.networkIcon);
        //     setCurrentChainIdName(networkIcon?.networkName);
        // };
const checkChainId = async () => {
    const chainId = await getCurrentChainId();
    const provider = await getCurrentProvider();
    console.log("rrrr",chainId);
    if (!chainId) {
        console.error("Invalid or null Chain ID.");
        return null;
    }

    const chainIdHex = ethers.utils.hexlify(chainId); // Convert chainId to hex using ethers.js
   console.log("chainIdHex",chainIdHex);

    const networkIcon = options.find(option => option.chainId === chainIdHex);
    console.log("networkIcon",networkIcon);
    // Update state with current chain icon and name
    setCurrentChainIdIcon(networkIcon?.networkIcon);
    setCurrentChainIdName(networkIcon?.networkName);
};

        checkChainId();
    }, [selectedNetwork, options]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (isOpen && networkOverlayRef.current && !networkOverlayRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    // const handleNetworkSelection = async (chainId) => {
    //     let provider = await getCurrentProvider();
    //     const web3 = new Web3(provider);
    //     try {
    //         await web3.currentProvider.request({
    //             method: 'wallet_switchEthereumChain',
    //             params: [{ chainId: chainId }],
    //         });
    //         window.location.reload();
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };
    const handleNetworkSelection = async (chainId) => {
        try {
            const provider = await getCurrentProvider();
          if (provider.provider.request) {
                await provider.provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: ethers.utils.hexValue(chainId) }], // Use ethers.utils.hexValue to format chainId as hex
                });
                window.location.reload();
            } else {
                console.error("Provider does not support network switching");
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    return (
        <div ref={networkOverlayRef} style={{ position: 'relative', zIndex: 1000 }}>
            <button
                id="selectedOptionBtn"
                ref={networkOverlayRef}
                type="button"
                className="btnGroupDrop1"
                onClick={toggleDropdown}
            >
                {selectedNetwork?.icon && (
                    <img src={currentChainIdIcon} className="ms-2 networks-icon" alt="" style={{ height: '23px', width: '23px' }} />
                )}
                <img
                    src="/images/dropdown.png"
                    className={`icons ${isOpen ? 'rotated' : ''}`}
                    alt=""
                    style={{ height: '23px', width: '23px' }}
                />
                {isOpen && (
                    <div className="network-overlay">
                        {options.map(option => (
                            <Option
                                key={option.key}
                                networkName={option.networkName}
                                networkIcon={option.networkIcon}
                                networkId={option.chainId}
                                isSelected={option.networkName === currentChainIdName}
                                onSelect={onSelectNetwork}
                                onClick={() => handleNetworkSelection(option.chainId)}
                            />
                        ))}
                    </div>
                )}
            </button>
        </div>
    );
};

export default NetworkOverlay;