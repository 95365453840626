import React, { useEffect, useState } from 'react';
import LiquidityTable from './LiquidityTable';
import backwardarrow from '../Assets/images2/backwardarrow.png';
import forwardarrow from '../Assets/images2/forwardarrow.png';
import LiquidityParent from '../LiquidtyContent/LiquidityParent';
import BarChart from '../Dropdown/BarChart';
import { getCurrentChainId, URLDOMAIN } from '../ContractAction/ContractDependency';

const LiquidityHub = () => {
  const [timeFrameClass, setTimeFrameClass] = useState({
    D: 'd-wrapper',
    W: 'w-wrapper',
    p: 'd-wrapper',
    q: 'w-wrapper',
    r: 'w-wrapper',
    s: 'w-wrapper',
    t: 'w-wrapper',
  });

  const [currentChainId, setCurrentChainId] = useState('');
  const [yearData, setYearData] = useState({ data: [], total: 0 });
  const [monthData, setMonthData] = useState({ data: [], total: 0 });
  const [dayData, setDayData] = useState({ data: [], total: 0 });
  const [barChartData, setBarChartData] = useState([]);

  const handleTimeFrameClick = async (timeFrame) => {
    const updatedClass = Object.fromEntries(
      Object.entries(timeFrameClass)?.map(([key, value]) => [
        key,
        key === timeFrame ? 'd-wrapper' : 'w-wrapper',
      ])
    );
    setTimeFrameClass(updatedClass);

    try {
      const fetchedChainId = await getCurrentChainId();
      const apiUrlMap = {
        p: `${URLDOMAIN}api/transction/getTradeVolTimeyear?chainid=${fetchedChainId}`, // 1M
        q: `${URLDOMAIN}api/transction/getTradeVolTimemonth?chainid=${fetchedChainId}`, // 3M
        r: `${URLDOMAIN}api/transction/getTradeVolTimeday?chainid=${fetchedChainId}`, // 5M
      };

      const apiUrl = apiUrlMap[timeFrame];
      const response = await fetch(apiUrl);
      if (!response.ok) {
        console.error(`Failed to fetch data: ${response.status} ${response.statusText}`);
        return;
      }
      const data = await response.json();
      const formattedData = data?.data?.map((item) => ({
        x: item.timePeriod,
        y: item.totalAmountAUSD,
      }));

      setBarChartData(formattedData);
    } catch (error) {
      console.error('Error fetching data for the selected timeframe:', error);
    }
  };

  useEffect(() => {
    const fetchCurrentChainIdAndData = async () => {
      try {
        const fetchedChainId = await getCurrentChainId();
        setCurrentChainId(fetchedChainId);
        console.log('Current Chain ID:', fetchedChainId);

        if (!fetchedChainId) {
          console.error('Chain ID not set, cannot fetch data.');
          return;
        }

        const fetchData = async (url, label) => {
          try {
            const response = await fetch(url);
            if (!response.ok) {
              console.error(`Failed to fetch data: ${response.status} ${response.statusText}`);
              return;
            }
            const data = await response.json();
            const formattedData = data?.data.map((item) => ({
              x: item.timePeriod,
              y: item.totalAmountAUSD,
            }));
            const totalAUSD = formattedData.reduce((acc, item) => acc + item.y, 0);

            if (label === 'Year') setYearData({ data: formattedData, total: totalAUSD });
            else if (label === 'Month') setMonthData({ data: formattedData, total: totalAUSD });
            else if (label === 'Day') setDayData({ data: formattedData, total: totalAUSD });
          } catch (error) {
            console.error(`Error fetching ${label} data:`, error);
          }
        };

        const apiUrls = [
          { url: `${URLDOMAIN}api/transction/getTradeVolTimeyear?chainid=${fetchedChainId}`, label: 'Year' },
          { url: `${URLDOMAIN}api/transction/getTradeVolTimemonth?chainid=${fetchedChainId}`, label: 'Month' },
          { url: `${URLDOMAIN}api/transction/getTradeVolTimeday?chainid=${fetchedChainId}`, label: 'Day' },
        ];

        apiUrls.forEach(({ url, label }) => fetchData(url, label));
      } catch (error) {
        console.error('Error fetching current chain ID or data:', error);
      }
    };

    fetchCurrentChainIdAndData();
  }, [URLDOMAIN]);

  return (
    <>
      <div
        className="container mt-3"
        style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'start', gap: '0px 40px' }}
      >
        <div>
          <LiquidityParent time="Since 2023-09-18" volumnData={yearData.total} />
        </div>
        <div>
          <LiquidityParent time="Last 30 days" volumnData={monthData.total} />
        </div>
        <div>
          <LiquidityParent time="Last 24 hours" volumnData={dayData.total} />
        </div>
      </div>
      <div className="container glassmorphism mt-5" style={{ borderRadius: '16px' }}>
        <div className="d-flex align-items-start justify-content-between px-3">
          <div>
            <p className="m-0 p-0" style={{ fontSize: '16px', color: '#C7CAD9' }}>
              Liquidity hub
            </p>
          </div>
          <div className="parent-frame ">
            <button className={timeFrameClass.p} onClick={() => handleTimeFrameClick('p')}>
              1M
            </button>
            <button className={timeFrameClass.q} onClick={() => handleTimeFrameClick('q')}>
              3M
            </button>
            <button className={timeFrameClass.r} onClick={() => handleTimeFrameClick('r')}>
              5M
            </button>
            <button className={timeFrameClass.s} onClick={() => handleTimeFrameClick('s')}>
              1Y
            </button>
            <button className={timeFrameClass.t} onClick={() => handleTimeFrameClick('t')}>
              All
            </button>
          </div>
        </div>
        <div className="px-3">
          <BarChart data={barChartData} />
        </div>
      </div>
      <div
        className="container mt-5 px-0"
        style={{ backgroundColor: '#01336080', borderRadius: '8px' }}
      >
        <div className="d-flex align-items-start justify-content-between mt-3 mx-4 p-2 px-3">
          <p className="m-0 p-0" style={{ fontSize: '16px', fontWeight: '600', color: '#C7CAD9' }}>
            LH Swaps
          </p>
          <div className="arrowparent ">
            <img
              className="arrow me-3"
              alt=""
              src={backwardarrow}
              style={{ height: '16px', width: '16px' }}
            />
            <img
              className="arrow"
              alt=""
              src={forwardarrow}
              style={{ height: '16px', width: '16px' }}
            />
          </div>
        </div>
        <LiquidityTable />
      </div>
    </>
  );
};

export default LiquidityHub;
