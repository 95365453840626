import React, { useState } from 'react'
import Context from '../Context/Context';
import { init, useConnectWallet } from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect'
const apiKey = '1730eff0-9d50-4382-a3fe-89f0d34a2070';

// Injected wallet setup
const injected = injectedModule();
const walletConnect = walletConnectModule({
  projectId: '9754fe0ca527dd492a84d4ac073d1927', // WalletConnect v2 project ID
  requiredChains: [97], // Base Mainnet chain ID in decimal format
  optionalChains: [
    1,     // Ethereum Mainnet
    42161, // Arbitrum One
    8453,  // Base Mainnet
    10,    // Optimism
    137,   // Polygon Mainnet
    56,    // Binance Smart Chain Mainnet
  ],
  dappUrl: 'https://trenddx.io/', // URL of your DApp
});

const infuraKey = '<INFURA_KEY>';
const rpcUrl = `https://mainnet.infura.io/v3/${infuraKey}`;

// Initialize Web3Onboard
init({
  apiKey,
  wallets: [injected, walletConnect],
  chains: [
    {
      id: '0x5', // Goerli Testnet
      token: 'ETH',
      label: 'Ethereum Goerli',
      rpcUrl: 'https://goerli.infura.io/v3/<INFURA_KEY>',
    },
    {
      id: '0x66eeb', // Arbitrum Goerli Testnet
      token: 'ARB-ETH',
      label: 'Arbitrum Goerli',
      rpcUrl: 'https://goerli-rollup.arbitrum.io/rpc',
    },
    {
      id: '0x1a4', // Optimism Goerli Testnet
      token: 'OPT',
      label: 'Optimism Goerli',
      rpcUrl: 'https://goerli.optimism.io',
    },
    {
      id: '0x13881', // Polygon Mumbai Testnet
      token: 'MATIC',
      label: 'Polygon Mumbai',
      rpcUrl: 'https://rpc-mumbai.maticvigil.com',
    },
    {
      id: '0x61', // BNB Smart Chain Testnet
      token: 'tBNB',
      label: 'BNB Smart Chain Testnet',
      rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    },
    {
      id: '0xa869', // Avalanche Fuji Testnet
      token: 'AVAX',
      label: 'Avalanche Fuji',
      rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
    },
    {
      id: '0xaef3', // Celo Alfajores Testnet
      token: 'CELO',
      label: 'Celo Alfajores',
      rpcUrl: 'https://alfajores-forno.celo-testnet.org',
    },
    {
      id: '0x4a83', // Base Goerli Testnet
      token: 'ETH',
      label: 'Base Goerli',
      rpcUrl: 'https://goerli.base.org',
    },
  ],
});

export default function Dropdownstate({ children }) {

  const [selectedNetwork, setSelectedNetwork] = useState({ name: 'Ethereum', icon: './images/sprint.png' });
  const [selectedToken1, setSelectedToken1] = useState({});
  const [selectedToken2, setSelectedToken2] = useState({});
  const [selectedToken1Address, setSelectedToken1Address] = useState('');
  const [selectedToken2Address, setSelectedToken2Address] = useState('');
  const [liquidityselectedToken1, setLiquiditySelectedToken1] = useState({});
  const [liquidityselectedToken2, setLiquiditySelectedToken2] = useState({});
  const [liquidityselectedToken1Address, setLiquiditySelectedToken1Address] = useState('');
  const [liquidityselectedToken2Address, setLiquiditySelectedToken2Address] = useState('');
  const [removeLqselectedToken1, setRemoveLqSelectedToken1] = useState({});
  const [removeLqselectedToken2, setRemoveLqSelectedToken2] = useState({});
  const [removelqSelectedToken1Address, setRemoveLqSelectedToken1Address] = useState('');
  const [removelqSelectedToken2Address, setRemoveLqSelectedToken2Address] = useState('');
  const [showNestedModal, setShowNestedModal] = useState(false);
  const [currentImportedTokenAddress, setCurrentImportedTokenAdderess] = useState('')
  const [customValue, setCustomValue] = useState('0.5');
  const [customValue1, setCustomValue1] = useState('10');
  const [activeSection, setActiveSection] = useState('overview');
  const [activeTab, setActiveTab] = useState('overview');
  const [searchtabledata, setSearchtabledata] = useState();
  const [searchTokentabledata, setSearchTokentabledata] = useState();
  const [searchPooltabledata, setSearchPooltabledata] = useState();
  const [timeFrame, setTimeFrame] = useState('D');
  const [timeFrameVolume, setTimeFrameVolume] = useState('1M');
  const [favorites, setFavorites] = useState([]);
  const [hoveredValue, setHoveredValue] = useState(0);
  const [hoveredDate, setHoveredDate] = useState(0);
  const [totalAmountAUSD, setTotalAmountAUSD] = useState(0);
  const [avgvalue, setAvgvalue] = useState(0);
  const [isBarHovered, setIsBarHovered] = useState(false);
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [selectedTokenforLiquidity1, setSelectedTokenforLiquidity1] = useState();
  const [selectedTokenforLiquidity2, setSelectedTokenforLiquidity2] = useState();
  const openNestedModal = () => {
    setShowNestedModal(true);
  };
  const closeNestedModal = () => {
    setShowNestedModal(false);
  };

  return (
    <Context.Provider value={{
      selectedNetwork, setSelectedNetwork,
      selectedToken1, setSelectedToken1,
      selectedToken2, setSelectedToken2,
      selectedToken1Address, setSelectedToken1Address,
      selectedToken2Address, setSelectedToken2Address,
      liquidityselectedToken2Address, setLiquiditySelectedToken2Address,
      liquidityselectedToken1Address, setLiquiditySelectedToken1Address,
      liquidityselectedToken2, setLiquiditySelectedToken2,
      liquidityselectedToken1, setLiquiditySelectedToken1,
      removeLqselectedToken1, setRemoveLqSelectedToken1,
      removeLqselectedToken2, setRemoveLqSelectedToken2,
      removelqSelectedToken1Address, setRemoveLqSelectedToken1Address,
      removelqSelectedToken2Address, setRemoveLqSelectedToken2Address,
      showNestedModal, openNestedModal, closeNestedModal,
      currentImportedTokenAddress, setCurrentImportedTokenAdderess,
      customValue, setCustomValue,
      customValue1, setCustomValue1,
      activeSection, setActiveSection,
      activeTab, setActiveTab,
      searchtabledata, setSearchtabledata,
      searchTokentabledata, setSearchTokentabledata,
      favorites, setFavorites,
      searchPooltabledata, setSearchPooltabledata,
      timeFrame, setTimeFrame,
      hoveredValue, setHoveredValue,
      totalAmountAUSD, setTotalAmountAUSD,
      hoveredDate, setHoveredDate,
      avgvalue, setAvgvalue,
      isBarHovered, setIsBarHovered,
      timeFrameVolume, setTimeFrameVolume,
       wallet, connecting , connect, disconnect,
       selectedTokenforLiquidity1, setSelectedTokenforLiquidity1,
       selectedTokenforLiquidity2, setSelectedTokenforLiquidity2
    }}>
      {children}
    </Context.Provider>
  )

}