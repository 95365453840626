import React, { useContext, useCallback, useState } from 'react';
import Total_Overview from './Total_Overview';
import Total_Tokens from './TotalTokens';
import Total_Pair from './Total_Pair';
import Context from '../Context/Context';

const Total = () => {

    const { activeSection, setActiveSection, activeTab, setActiveTab, searchtabledata } = useContext(Context);
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    const handleSearchClick = () => {
        setIsSearchVisible(!isSearchVisible);
    };

    const onFrameContainerClick = useCallback((section, tab) => {
        setActiveSection(section);
        setActiveTab(tab);
    }, [setActiveSection, setActiveTab]);

    const getTabStyle = (tabName) => ({
        backgroundColor: activeTab === tabName ? 'rgba(56, 122, 223, 0.5)' : 'transparent',
        color: activeTab === tabName ? 'white' : '#9b9797'
    });

    const handleSearch = useCallback((query) => {
        setSearchQuery(query);
        const filteredData = query.length > 0
            ? searchtabledata.filter(item => (item.pairName && item.pairName.toLowerCase().includes(query)) || (item.pairAddress && item.pairAddress.toLowerCase().includes(query)))
            : searchtabledata;
        setFilteredData(filteredData);
    }, [searchtabledata]);

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const debouncedSearch = useCallback(debounce((query) => handleSearch(query), 1000), [handleSearch]);

    const handleSearchPair = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        debouncedSearch(query);
    };

    return (
        <div>
            <div className="frame-parent1 container d-flex align-items-center justify-content-between px-0">
                <div className="total-group mt-3">
                    {['overview', 'tokens', 'pairs'].map(tab => (
                        <div key={tab} className={`tokens-wrapper ${activeTab === tab ? 'active' : ''}`} style={getTabStyle(tab)} onClick={() => onFrameContainerClick(tab, tab)}
                        >
                            <div className="overview">{tab.charAt(0).toUpperCase() + tab.slice(1)}</div>
                        </div>
                    ))}
                </div>
                {activeSection === 'pairs' && (
                    <div className='hrxVYA' style={{ cursor: 'pointer' }}>
                        <div className='d-flex align-item-center' style={{ padding: '8px' }}>
                            <img src="/images/search.png" height="20px" width="20px" alt="search icon" onClick={handleSearchClick} />
                        </div>
                        {isSearchVisible &&
                            <input type='search' className='px-2' value={searchQuery} onChange={handleSearchPair} autoFocus style={{ color: 'white', width: "100%", background: 'transparent', outline: 'none', border: 'transparent' }} placeholder='Search here' />}
                    </div>
                )}
                {activeSection === 'tokens' && (
                    <div className='hrxVYA' style={{ cursor: 'pointer' }}>
                        <div className='d-flex align-item-center' style={{ padding: '8px' }}>
                            <img src="/images/search.png" height="20px" width="20px" alt="search icon" onClick={handleSearchClick} />
                        </div>
                        {isSearchVisible &&
                            <input type='search' className='px-2' value={searchQuery} onChange={handleSearchPair} autoFocus style={{ color: 'white', width: "100%", background: 'transparent', outline: 'none', border: 'transparent' }} placeholder='Search here' />}
                    </div>
                )}
            </div>
            {activeSection === 'overview' && <Total_Overview />}
            {activeSection === 'tokens' && <Total_Tokens />}
            {activeSection === 'pairs' && <Total_Pair filteredData={filteredData} />}
        </div>
    );

};

export default Total;