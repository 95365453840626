import React, { useState, useContext, useEffect } from 'react'
import cross from '../Assets/images2/radix-icons_cross-2.svg';
// import alert from '../Assets/images2/alert.png'
import phquestion from "../Assets/images2/ph_question.png";
import { Link } from 'react-router-dom';
import Context from '../Context/Context';
import { getTokenSymbol } from '../ContractAction/TrendswapAction';
import { getImportedAddressExplorer } from '../ContractAction/ContractDependency';

const ImportModalContent = (props) => {

  const { selectedToken1Address, selectedToken2Address, liquidityselectedToken1Address, liquidityselectedToken2Address, removelqSelectedToken1Address, removelqSelectedToken2Address, currentImportedTokenAddress, setCurrentImportedTokenAdderess } = useContext(Context);
  const [isChecked, setIsChecked] = useState(false);
  // const [currentImportedTokenAddress, setCurrentImportedTokenAdderess] = useState('')
  const [currentImportedTokenSymbol, setCurrentImportedTokenSymbol] = useState('')
  const [currentImportedTrimAddress, setCurrentImportedTrimAddress] = useState('')

  console.log('selectedTokenAddresList', selectedToken1Address, selectedToken2Address, liquidityselectedToken1Address, liquidityselectedToken2Address, removelqSelectedToken1Address, removelqSelectedToken2Address)

  useEffect(() => {
    const checkCurrentImportedAddr = async () => {
      const recentAddresses = [
        selectedToken1Address,
        selectedToken2Address,
        liquidityselectedToken1Address,
        liquidityselectedToken2Address,
        removelqSelectedToken1Address,
        removelqSelectedToken2Address,
      ];
      console.log('recentAddresses', recentAddresses)
      const filteredAddresses = recentAddresses.filter(addr => addr && addr !== '');
      console.log('filteredAddresses', filteredAddresses)
      if (filteredAddresses.length > 0) {
        console.log('filteredAddresseslengthAddr', filteredAddresses[filteredAddresses.length - 1])
        setCurrentImportedTokenAdderess(filteredAddresses[filteredAddresses.length - 1]);
        const currentImportedAddrTokenSymbol = await getTokenSymbol(filteredAddresses[filteredAddresses.length - 1])
        setCurrentImportedTokenSymbol(currentImportedAddrTokenSymbol)
        const crrentImportedTrimAddr = await currentImportedTokenAddress.slice(0, 6) + "..." + currentImportedTokenAddress.slice(-4);
        setCurrentImportedTrimAddress(crrentImportedTrimAddr)
      }
    };
    const interval = setInterval(checkCurrentImportedAddr, 2000);
    console.log('currentImportedTokenAddress', currentImportedTokenAddress)
    console.log('currentImportedTokenSymbol', currentImportedTokenSymbol)
    checkCurrentImportedAddr();
    return () => clearInterval(interval);
  }, [selectedToken1Address, selectedToken2Address, liquidityselectedToken1Address, liquidityselectedToken2Address, removelqSelectedToken1Address, removelqSelectedToken2Address, currentImportedTokenAddress, setCurrentImportedTokenAdderess]);

  const handleTokenAddrExplorer = async () => {
    const addExplorer = await getImportedAddressExplorer(currentImportedTokenAddress)
    window.open(`${addExplorer}${currentImportedTokenAddress}`, '_blank');
  }


  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <>

      <div className='hfXcaM kMkmMS'>
        <div className='cPCYrp' style={{ border: "2px solid orange", color: '#fff', background: "rgba(255, 165, 0, 0.2)", borderRadius: 10, padding: 10, display: 'flex', alignItems: 'flex-start', justifyContent: "flex-start" }} >
          <span><img src="https://cdn-icons-png.flaticon.com/512/2556/2556974.png" style={{ height: '24px', width: '24px' }} alt='' /></span>
          <div className='ms-2'>Anyone can create tokens on BNB Smart Chain with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.
            If you purchase a fraudulent token, you may be exposed to permanent loss of funds.</div>
        </div>
        <div className='d-flex align-items-center justify-content-center' style={{ color: '#FF5BAE', border: '2px solid hotpink', borderRadius: 99, fontSize: 12, width: 'fit-content', padding: '5px' }}>
          <img src={phquestion} style={{ height: '20px', width: '20px' }} alt='' />
          <div > Unknown source</div>
        </div>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='text-light'>
            <div>{currentImportedTokenSymbol}</div>
            <div>{currentImportedTrimAddress}</div>
          </div>
          <div>
            <div style={{ borderRadius: 99, background: "#FF5BAE", padding: 5, fontSize: 12 }}>Scanning risk</div>
          </div>
        </div>
        <div>
          <Link rel="noopener noreferrer" onClick={handleTokenAddrExplorer}>View on Explorer</Link>
        </div>

        <div>
          <input class="form-check-input" onChange={handleCheckboxChange} type="checkbox" id="checkboxNoLabel" value="" aria-label="..." style={{cursor: 'pointer'}}/>
          <span className='ms-2 text-light'>I UnderStand</span>
        </div>
        <button className='connect-button' onClick={props.closeNestedModal} onHide={() => props.closeModal()}
          style={{
            background: isChecked ? '#FF5BAE' : 'grey',
            color: '#fff',
            fontWeight: 700
          }}>Import</button>
      </div>


      {/* FF5BAE */}
    </>
  )
}

export default ImportModalContent