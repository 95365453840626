import React, { useState, useEffect, useRef } from 'react';
import cross from '../Assets/images2/radix-icons_cross-2.svg';
import { useContext } from 'react';
import Context from '../Context/Context';
import NetworkOverlay from './NetworkOverlay';
import { getCurrentChainIdPopularTokens, getCurrentChainIdAllTokens, getTokenAddressExists, getDetailsOfTokenAddressExists } from '../ContractAction/TrendswapAction';

const SelectToken3 = (props, { onSelectToken }) => {

    const [searchQuery, setSearchQuery] = useState('');
    const [searchedTokenAddress, setSearchedTokenAddress] = useState('');
    const [isTokenAddressExists, setIsTokenAddressExists] = useState(false);
    const [allTokens, setAllTokens] = useState([]);
    const [selected, setSelected] = useState({});
    const inputRef = useRef(null);

    const { selectedNetwork, setSelectedNetwork, removeLqselectedToken1, setRemoveLqSelectedToken1, removeLqselectedToken2, setRemoveLqSelectedToken2, setRemoveLqSelectedToken1Address, setRemoveLqSelectedToken2Address, openNestedModal,selectedTokenforLiquidity1, setSelectedTokenforLiquidity1,selectedTokenforLiquidity2, setSelectedTokenforLiquidity2 } = useContext(Context);
    const filteredTokens = allTokens.filter(
        (token) =>
            token.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            token.ticker.toLowerCase().includes(searchQuery.toLowerCase()) ||
            token?.address?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const searchedTokens = (
        searchedTokenAddress &&
        (searchedTokenAddress?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            searchedTokenAddress?.ticker?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            searchedTokenAddress?.address?.toLowerCase().includes(searchQuery.toLowerCase()))
    ) ? [searchedTokenAddress] : [];
    const [popularTokens, setPopularTokens] = useState([]);

    const handleSelectNetwork = (network) => {
        setSelectedNetwork(network);
    };

    const handleCrossIconClick = () => {
        props.closeModal();
    };
    // const handleTokenSelect = (token) => {
    //     const selectedToken = {
    //         img: token.img,
    //         ticker: token.ticker,
    //         address: token.address
    //     };
    //     setSelected(onSelectToken(token.img, token.ticker));
    //     alert(token.ticker);

    //     if (selectedToken1.address!=='') {
    //         setSelectedToken1(selectedToken);
    //         setSelectedToken1Address(selectedToken.address); 
    //         console.log('Token 2 address:', selectedToken.address);

    //     } else if (!selectedToken2.address) {
    //         setSelectedToken2(selectedToken);
    //         setSelectedToken2Address(selectedToken.address); 
    //         console.log('Token 2 address:', selectedToken.address);

    //     }
    // };
    const handleTokenSelect = (token) => {
        const selectedToken = {
            img: token.img,
            ticker: token.ticker,
            address: token.address
        };
        if (typeof props.onSelectToken === 'function') {
            setSelected(props.onSelectToken(token.img, token.ticker));
        };
        // alert(token.ticker);
       console.log("selectedToken1111112233",token)

        console.log('tokenticker:', token.ticker);
        if (props.showModal1 === true) {
            setRemoveLqSelectedToken1(selectedToken);
            setSelectedTokenforLiquidity1(selectedToken);//State added by shreyash
            setRemoveLqSelectedToken1Address(selectedToken.address);
            console.log('Token 1 address:', selectedToken.address);
            console.log("selectedTokenforLiquidity1", selectedTokenforLiquidity1)

        } else if (props.showModal2 === true) {
            setRemoveLqSelectedToken2(selectedToken);
            setSelectedTokenforLiquidity2(selectedToken);//State added by shreyash
            setRemoveLqSelectedToken2Address(selectedToken.address);
            console.log('Token 2 address:', selectedToken.address);
            console.log("selectedTokenforLiquidity2", selectedTokenforLiquidity2)

        }
    };
    console.log('Token1', removeLqselectedToken1.address);
    console.log('Token2', removeLqselectedToken2.address)


    const handlePopularTokenSelect = (index) => {
        if (popularTokens && popularTokens.length > index) {
            const popularToken = popularTokens[index];
            const alreadySelected = filteredTokens.some(token => token.name === popularToken.name);
            if (!alreadySelected) {
                setSelected(props.onSelectToken(popularToken.imgSrc, popularToken.name));
                if (props.showModal1 === true) {
                    setRemoveLqSelectedToken1(popularToken);
                    setRemoveLqSelectedToken1Address(popularToken.address);
                    console.log('Token 1 address:', popularToken.address);
                } else if (props.showModal2 === true) {
                    setRemoveLqSelectedToken2(popularToken);
                    setRemoveLqSelectedToken2Address(popularToken.address);
                    console.log('Token 2 address:', popularToken.address);
                }
                // alert(popularToken.name);
                console.log('popularTokenName:', popularToken.name);
            } else {
                console.log("Token already selected from the first section.");
            }
        }
    };

    useEffect(() => {
        const handleSearchTokenAddress = async () => {
            if (searchQuery.length > 0) {
                const tokenExits = await getTokenAddressExists(searchQuery);
                setIsTokenAddressExists(tokenExits);
                // alert(isTokenAddressExists)
                console.log("tokenExits", isTokenAddressExists);
                if (tokenExits === true) {
                    const tokenDetails = await getDetailsOfTokenAddressExists(searchQuery);
                    console.log("tokenDetails11", tokenDetails);
                    console.log("tokenDetailsimg", tokenDetails[0].img);
                    console.log("tokenDetailsname", tokenDetails[0].name);
                    console.log("tokenDetailsticker", tokenDetails[0].ticker);
                    console.log("tokenDetailsaddress", tokenDetails[0].address);
                    // alert("tokenDetailsaddress1: "+tokenDetails[0].address)
                    filteredTokens.push(tokenDetails[0]);
                    console.log("filteredTokens", filteredTokens);
                    console.log("filteredTokenslength", filteredTokens.length);
                }
                if (tokenExits === false) {
                    const tokenDetails = await getDetailsOfTokenAddressExists(searchQuery);
                    setSearchedTokenAddress(tokenDetails[0]);
                    console.log("tokenDetails", tokenDetails);
                    console.log("tokenDetailsimg", tokenDetails[0].img);
                    console.log("tokenDetailsname", tokenDetails[0].name);
                    console.log("tokenDetailsticker", tokenDetails[0].ticker);
                    console.log("tokenDetailsaddress", tokenDetails[0].address);
                    // alert("tokenDetailsaddress2: "+tokenDetails[0].address)
                    filteredTokens.push(tokenDetails[0]);
                    console.log("filteredTokens", filteredTokens);
                    console.log("filteredTokenslength", filteredTokens.length);
                    // setSelected(onSelectToken(tokenDetails[0].img, tokenDetails[0].ticker));
                }
            }
        }
        handleSearchTokenAddress()
    }, [searchQuery])

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        const fetchPopularTokens = async () => {
            const tokens = await getCurrentChainIdPopularTokens();
            console.log("tokens", tokens);
            setPopularTokens(tokens);
            console.log("popularTokens", tokens);
            const allChainTokens = await getCurrentChainIdAllTokens();
            setAllTokens(allChainTokens);
            console.log("allChainTokens", allChainTokens);
        };

        fetchPopularTokens();
    }, []);


    return (
        <>
            <div className='gnwYND bFCsHr'>
                <div className='hfXcaM kMkmMS'>
                    <div className='cPCYrp d-flex justify-content-between  align-items-center'>
                        <div style={{ fontSize: '20px', color: '#b1b1b1' }}>Select a token</div>
                        <img src={cross} onClick={handleCrossIconClick} style={{ height: '24px', width: '24px', cursor: 'pointer' }} alt='' />
                    </div>
                    <div className='kdYshZ dKubqp'>
                        <div className=' hTynGq' style={{ width: '72%', display: 'flex', justifyContent: 'space-between' }}>
                            <img
                                src='/images/search.png'
                                className='ms-3'
                                style={{ height: '20px', width: '20px' }}
                                alt=''
                            />
                            <input
                                type='text'
                                id='token-search-input'
                                className='w-100 hTynGqr'
                                placeholder='Search name or paste address'
                                ref={inputRef}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                        <NetworkOverlay selectedNetwork={selectedNetwork} id="network-overlay1" onSelectNetwork={handleSelectNetwork} />
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px 20px' }}>
                        {popularTokens && popularTokens.map((token, index) => (
                            <div key={index} className='fxVDAj kdYshZ dKubqp' onClick={() => handlePopularTokenSelect(index)}>
                                <div className='crYogb'>
                                    <img src={token.imgSrc} style={{ borderRadius: "50%", height: "23px", width: "23px" }} alt="" />
                                    <span>{token.name} </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='hfXcaM kMkmMS scrollbar '>
                    <div className='text-light'>
                    </div>
                    {/* {isTokenAddressExists ? (
                        <>
                            {filteredTokens.length === 0 ? (
                                <div className='text-light'>No tokens found</div>
                            ) : (
                                filteredTokens.map((token, index) => (
                                    <div className="cPCYrp dKubqp bIFEzi cFvOnL alertdiv" key={index} onClick={() => handleTokenSelect(token)}>
                                        <div className="bFCsHr"><img src={token.img} style={{ height: '24px', width: '24px', borderRadius: '50%' }} alt='' /></div>
                                        <div className="eENUcJ">
                                            <div className="dKubqp dKubqptext cPCYrp">{token.name}</div>
                                            <div className="css-1m0dqmt jgbRhf" value={selected}>{token.ticker}</div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </>
                    ) : (
                        <>
                            {searchedTokens.length === 0 ? (
                                <div className='text-light'>No tokens found</div>
                            ) : (
                                searchedTokens.map((token, index) => (
                                    <div className="cPCYrp dKubqp bIFEzi cFvOnL alertdiv" key={index} onClick={() => handleTokenSelect(token)}>
                                        <div className="bFCsHr"><img src={token.img} style={{ height: '24px', width: '24px', borderRadius: '50%' }} alt='' /></div>
                                        <div className="eENUcJ">
                                            <div className="dKubqp dKubqptext cPCYrp">{token.name}</div>
                                            <div className="css-1m0dqmt jgbRhf" value={selected}>{token.ticker}</div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </>
                    )} */}


                    {filteredTokens.length === 0 && isTokenAddressExists ? (
                        <div className='text-light'>No tokens found</div>
                    ) : (
                        filteredTokens.map((token, index) => (
                            <div className="cPCYrp dKubqp bIFEzi cFvOnL alertdiv" key={index} onClick={() => handleTokenSelect(token)}>
                                <div className="bFCsHr"><img src={token.img} style={{ height: '24px', width: '24px', borderRadius: '50%' }} alt='' /></div>
                                <div className="eENUcJ">
                                    <div className="dKubqp dKubqptext cPCYrp">{token.name}</div>
                                    <div className="css-1m0dqmt jgbRhf" value={selected}>{token.ticker}</div>
                                </div>
                            </div>
                        ))
                    )}
                    {!filteredTokens.length && !isTokenAddressExists ? (
                        searchedTokens.length === 0 ? (
                            <div className='text-light'>No tokens found</div>
                        ) : (
                            searchedTokens.map((token, index) => (
                                <div className="cPCYrp dKubqp bIFEzi cFvOnL alertdiv" key={index} onClick={() => handleTokenSelect(token)}>
                                    <div className="bFCsHr"><img src={token.img} style={{ height: '24px', width: '24px', borderRadius: '50%' }} alt='' /></div>
                                    <div className="eENUcJ">
                                        <div className="dKubqp dKubqptext cPCYrp">{token.name}</div>
                                        <div className="css-1m0dqmt jgbRhf" value={selected}>{token.ticker}</div>
                                    </div>
                                    <div style={{ flex: '1' }}></div>
                                    <button className='btn btn-danger' onClick={openNestedModal}>Import</button>
                                </div>
                            ))
                        )
                    ) : null}
                </div>

            </div>
        </>
    );
};

export default SelectToken3;
