import React from 'react';
import timeimg from '../Assets/images2/carbon_time.png';

const LiquidityParent = ({ volumnData = 0, time }) => {
    return (
        <div>
            <div className='liquidity-parent glassmorphism'>
                <div className='d-flex liquiditytext align-items-center justify-content-between'>
                    <p>Volume</p>
                    <p className='days'>{time}</p>
                </div>
                <p className='text-center fs'>${volumnData.toFixed(2)}</p>
                <div className='d-flex liquiditytext align-items-center justify-content-between'>
                    <p className='m-0'>@orbs</p>
                    <p className='m-0 an-hour-ago'>
                        <span><img src={timeimg} alt='..' /></span> an hour ago
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LiquidityParent;
