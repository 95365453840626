import React, { useContext, useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import pharrow from "../Assets/images2/ph_arrow-down.png";
import phquestion from "../Assets/images2/ph_question.png";
import plus from "../Assets/images2/importbutton.png";
import MyNavbar from '../Component/MyNavbar';
import { Link } from 'react-router-dom';
import cross from '../Assets/images2/radix-icons_cross-2.svg';
import downarrow from '../Assets/images2/downarrow.png'
import { Modal } from 'react-bootstrap';
import Context from '../Context/Context';
import SelectToken3 from '../Dropdown/SelectToken3';
import ImportModalContent from '../Dropdown/ImportModalContent';
import { getUserPairBalance } from '../ContractAction/TrendswapAction'
import {getDefaultNativeTokenAddress,getTokenBalance} from '../ContractAction/TrendswapAction'
import {getPairTotalSupplyAndPoolSharePerc} from '../ContractAction/TrendswapAction'
const ImportPool = () => {

    const { selectedNetwork, setSelectedNetwork, removelqSelectedToken1Address, removelqSelectedToken2Address, showNestedModal, closeNestedModal,setRemoveLqSelectedToken1Address,
        setRemoveLqSelectedToken2Address ,setSelectedTokenforLiquidity1, setSelectedTokenforLiquidity2, wallet} = useContext(Context);
    const [selectedTokenImage1, setSelectedTokenImage1] = useState('');
    const [selectedTokenImage2, setSelectedTokenImage2] = useState('');
    const [selectedTokenTicker1, setSelectedTokenTicker1] = useState('');
    const [selectedTokenTicker2, setSelectedTokenTicker2] = useState('');
    const [userPairTokenAandBBal, setUserPairTokenAandBBal] = useState('');
    const [userPoolSharePerc,setUserPoolSharePerc] = useState(0);
    const [showPool, setShowPool] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);
    const manage = () => setShowPool(true);
    const handleSelectNetwork = (network) => setSelectedNetwork(network);
console.log("userPairTokenAandBBal",userPairTokenAandBBal);
    console.log(showPool);
    console.log("removelqSelectedToken1AddressremovelqSelectedToken2Address ", removelqSelectedToken1Address, removelqSelectedToken2Address)
    console.log("selectedTokenImage1selectedTokenImage2 ", selectedTokenImage1, selectedTokenImage2)
useEffect(()=>{
    setRemoveLqSelectedToken1Address('');
     setRemoveLqSelectedToken2Address('');
},[])

useEffect(() => {
    const fetchDefaultNativeTokenAddress = async () => {
        try {
            console.log("fetchDefaultNativeTokenAddress gets called");

            const defaultNativeTokenData = await getDefaultNativeTokenAddress();
            console.log("defaultNativeTokenData", defaultNativeTokenData);

            if (defaultNativeTokenData?.nativeTokenAddress) {
                const { 
                    nativeTokenAddress, 
                    nativeTokenTicker, 
                    nativeTokenImg 
                } = defaultNativeTokenData;

                console.log(
                    "defaultNativeTokenAddress",
                    nativeTokenAddress,
                    nativeTokenTicker,
                    nativeTokenImg
                );

                // Construct the token data object
                const tokenData = {
                    address: nativeTokenAddress,
                    ticker: nativeTokenTicker,
                    img: nativeTokenImg,
                };

                // Update states
                setRemoveLqSelectedToken1Address(nativeTokenAddress);
                setSelectedTokenforLiquidity1(tokenData);
                setSelectedTokenTicker1(nativeTokenTicker);
                setSelectedTokenImage1(nativeTokenImg);

                // Log values directly from the tokenData instead of relying on async state updates
                console.log(
                    "fetchDefaultNativeTokenAddress (from variables):",
                    tokenData.ticker,
                    tokenData.img
                );
            }
        } catch (error) {
            console.error("Error fetching default native token address:", error);
        }
    };

    fetchDefaultNativeTokenAddress();
}, [wallet]);




    const openModal = (buttonId) => {
        try {
            buttonId === 1 ? setShowModal1(true)
                : buttonId === 2 ? setShowModal2(true)
                    : console.error(`Invalid buttonId for opening modal: ${buttonId}`);
        } catch (error) {
            console.error(error.message);
        }
    };
    const closeModal = (buttonId) => {
        try {
            buttonId === 1 ? setShowModal1(false)
                : buttonId === 2 ? setShowModal2(false)
                    : console.error(`Invalid buttonId for closing modal: ${buttonId}`);
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleTokenSelectModal1 = (image, ticker) => {
        try {
            if (ticker === selectedTokenTicker2) {
                console.log("Token already selected in Modal 2");
            } else {
                setSelectedTokenTicker1(ticker);
                setSelectedTokenImage1(image);
                closeModal(1);
            }
        } catch (error) {
            console.error("Error in handleTokenSelectModal1:", error);
        }
    };
    const handleTokenSelectModal2 = (image, ticker) => {
        try {
            if (ticker === selectedTokenTicker1) {
                console.log("Token already selected in Modal 1");
            } else {
                setSelectedTokenTicker2(ticker);
                setSelectedTokenImage2(image);
                closeModal(2);
            }
        } catch (error) {
            console.error("Error in handleTokenSelectModal2:", error);
        }
    };

    const fetchUserPairBalance = useCallback(debounce(async () => {
        if (!removelqSelectedToken1Address || !removelqSelectedToken2Address) {
            console.warn("Invalid token addresses:", removelqSelectedToken1Address, removelqSelectedToken2Address);
            setUserPairTokenAandBBal(0);
            return;
        }
        try {
            const balance = await getUserPairBalance(removelqSelectedToken1Address, removelqSelectedToken2Address);
            setUserPairTokenAandBBal(balance);
            const perc = await getPairTotalSupplyAndPoolSharePerc(removelqSelectedToken1Address, removelqSelectedToken2Address);
            setUserPoolSharePerc(perc[1] || 0);
        } catch (error) {
            console.error("Error fetching user pair balance:", error);
            setUserPairTokenAandBBal(0);
        }
    }, 300), [removelqSelectedToken1Address, removelqSelectedToken2Address]);

    useEffect(() => {
        fetchUserPairBalance();
        return () => {
            fetchUserPairBalance.cancel();
        };
    }, [fetchUserPairBalance]);


    useEffect(() => {
        document.title = 'Trenddx-Import Pool';
        return () => {
            document.title = 'Trenddx';
        };
    }, []);

    return (
        <>
            <MyNavbar selectedNetwork={selectedNetwork} onSelectNetwork={handleSelectNetwork} />
            <div className="jRlUBU" style={{ margin: '120px auto' }}>
                <div className="khwJOa glassmorphism">
                    <div className=" d-flex justify-content-between mt-3 align-items-center">
                        <Link to='/pool'> <img src={pharrow} style={{ height: "20px", width: "20px" }} alt="" /></Link>
                        <button className="swaptext">Import Pool</button>
                        <img src={phquestion} style={{ height: "20px", width: "20px" }} alt="" />
                    </div>
                    <button onClick={() => openModal(1)} className='import-pool-parent w-100 px-3 mt-2 carder carderbg'>
                        <div>
                            {selectedTokenImage1 ? (
                                <>
                                    <img src={selectedTokenImage1} style={{ height: '24px', width: '24px', borderRadius: '50%' }} alt='' />
                                    <span className='text-light ms-2'>{selectedTokenTicker1}</span>
                                </>
                            ) : (
                                <>
                                    <img src='./images/sprint.png' style={{ height: '24px', width: '24px', borderRadius: '50%' }} alt='' />
                                    <span className='text-light ms-2'>ETH</span>
                                </>
                            )}
                        </div>
                        <img src="/images/dropdown.png" height="23px" width="23px" alt="" />
                    </button>
                    <div className='swapoption d-flex justify-content-center align-items-center'>
                        <button className="px-1">
                            <img src={plus} className="p-1" height="36px" width="36px" alt="" />
                        </button>
                    </div>
                    <button onClick={() => { openModal(2); manage(); }} className='import-pool-parent w-100 px-3 carder carderbg'>
                        <div>
                            {selectedTokenImage2 ? (
                                <>
                                    <img src={selectedTokenImage2} style={{ height: '24px', width: '24px', borderRadius: '50%' }} alt='' />
                                    <span className='text-light ms-2'>{selectedTokenTicker2}</span>
                                </>
                            ) : (
                                <>
                                    Select Token
                                </>
                            )}
                        </div>
                        <img src="/images/dropdown.png" height="23px" width="23px" alt="" />
                    </button>
                  
                    {userPairTokenAandBBal == "0" && removelqSelectedToken1Address?.length > 0 && removelqSelectedToken2Address?.length > 0 ? (
                        <>
                            <div className='text-center mt-2'>
                                <div className='pool-found'>No pool found.</div>
                            </div>
                            <div className="create-pair-container">
                                <Link to='/pool=add Liquidty' className='create-pair-button'>
                                    Create Pair
                                </Link>
                            </div>
                        </>
                    ) : userPairTokenAandBBal > "0" && removelqSelectedToken1Address?.length > 0 && removelqSelectedToken2Address?.length > 0 ? (
                        <div className='text-center mt-2'>
                            <div className='pool-found'>Pool Found!</div>
                            <Link to='/manage=Pool' className='manage-this-pool'>Manage this pool</Link>
                        </div>
                    ) : null}
                    {removelqSelectedToken1Address?.length > 0 || removelqSelectedToken2Address?.length > 0 ? (
                        <></>
                    ) : (
                        <div className='swapoption mb-4 import-pool-parent d-flex justify-content-center morphism mt-2 p-4 align-items-center'>
                            <span className='select-a-token'>Select a token to find your liquidity</span>
                        </div>
                    )}
                     {selectedTokenTicker1 && selectedTokenTicker2 && <div className="khwJOa glassmorphism mt-2 mb-2" style={{ border: "none" }}>
            {/* <NewPosition /> */}
            <div className="px-2">
              <div className="_883856ad ">Your Position</div>
              <div className=" _883856ad">
                <div className="d-flex">
                  {/* <img src={phquestion} style={{ height: "24px", width: "24px" }} alt="" /> */}
                  <img src={phquestion} style={{ height: "24px", width: "24px" }} alt="" />
                  <div>{selectedTokenTicker1}/{selectedTokenTicker2}</div>
                </div>
                <div>{userPairTokenAandBBal ?? "0"}</div>
              </div>
              <div className=" _883856ad">
                <div>Your pool share</div>
                <div>{userPoolSharePerc.toFixed(2)}%</div>
              </div>
              <div className=" _883856ad">
                <div>{selectedTokenTicker1}:</div>
                <div>{selectedTokenTicker2}</div>
              </div>
              {/* <div className=" _883856ad">
                <div>{selectedTokenTicker1}:</div>
                <div>{selectedTokenTicker2}</div>
              </div> */}
            </div>
          </div>}
                </div>
                
            </div>
            
            {/* Bootstrap Modal */}
            <div className="modal" style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className=" _883856ad">
                                <div>You will receive</div>
                                <div><img src={cross} data-bs-dismiss="modal" aria-label="Close" style={{ height: '24px', width: '24px' }} onClick={handleModalClose} alt='' /></div>
                            </div>
                            <div className="x905656cc48b31b9b6b0d298486901-parent">
                                <div className="x905656cc48b31b9b6b0d298486901">0x905656cC48B31B9b6b0D298486901</div>
                            </div>
                            <div className='_883856ad'>
                                <div className='d-flex align-items-center'>
                                    <div>Common bases</div>
                                    <img src={phquestion} style={{ height: "20px", width: "20px" }} alt="" />
                                </div>
                            </div>
                            <div className='d-flex' style={{ gap: '0px 10px' }}>
                                <div className='crYogb'>
                                    <img src='./images/sprint.png' height="23px" width="23px" alt="" />
                                    <span>ETH</span>
                                </div>
                                <div className='crYogb'>
                                    <img src='https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png' height="23px" width="23px" alt="" />
                                    <span>DAI</span>
                                </div>
                            </div>
                            <div style={{ borderBottom: '1px solid #515151 ' }}>
                                <div className="token-name-parent">
                                    <div className="token-name">Token Name</div>
                                    <div className="pharrow-down-wrapper">
                                        <img className="pharrow-down-icon" alt="" src={downarrow} style={{ height: '20px', width: '20px' }} />
                                    </div>
                                </div>
                                <div className='_883856ad mb-5'>
                                    <div className='d-flex align-items-center' style={{ gap: '0px 10px' }}>
                                        <img src={phquestion} style={{ height: "24px", width: "24px" }} alt="" />
                                        <div>
                                            <div>Token1</div>
                                            <div className="found-by-address-parent">
                                                <div className="found-by-address">Found by address</div>
                                                <div className="add">(Add)</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        31000000
                                    </div>
                                </div>
                            </div>
                            <div className="select-a-list-parent">
                                <div className="select-a-list">Select a list</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal1} onHide={() => closeModal(1)}
                style={{ borderRadius: '15px' }}
                dialogClassName='modal-dialog-centered'>
                <Modal.Body className='p-0 modal-body'>
                    <SelectToken3 showModal1={showModal1} closeModal={() => closeModal(1)} onSelectToken={(image, ticker) => handleTokenSelectModal1(image, ticker, 1)} />
                </Modal.Body>
            </Modal>
            <Modal show={showModal2} onHide={() => closeModal(2)}
                style={{ borderRadius: '15px' }}
                dialogClassName='modal-dialog-centered'>
                <Modal.Body className='p-0 modal-body'>
                    <SelectToken3 showModal2={showModal2} closeModal={() => closeModal(2)} onSelectToken={(image, ticker) => handleTokenSelectModal2(image, ticker, 2)} />
                </Modal.Body>
            </Modal>
            <Modal show={showNestedModal} onHide={closeNestedModal}>
                <div className='gnwYND bFCsHr'>
                    <Modal.Header style={{ borderBottom: '1px solid #585858' }}>
                        <img src={pharrow} style={{ height: "20px", width: "20px" }} alt="" />
                        <span className='text-light'>Import Tokens</span>
                        <img src={cross} style={{ height: '24px', width: '24px' }} onClick={closeNestedModal} alt='' />
                    </Modal.Header>
                    <Modal.Body className='p-0 modal-body'>
                        <ImportModalContent closeNestedModal={closeNestedModal} closeModal={closeModal} />
                    </Modal.Body>
                </div>
            </Modal>

        </>
    );

};

export default ImportPool;