// import React, { useState, useEffect } from 'react';
// import Web3 from "web3"; // Import Web3 library
// import { Link } from 'react-router-dom';
// import { init, useConnectWallet } from '@web3-onboard/react'
// import injectedModule from '@web3-onboard/injected-wallets'
// import { ethers } from 'ethers'
// const apiKey = '1730eff0-9d50-4382-a3fe-89f0d34a2070'
// const injected = injectedModule()
// const infuraKey = '<INFURA_KEY>'
// const rpcUrl = `https://mainnet.infura.io/v3/${infuraKey}`
// init({
//     // This javascript object is unordered meaning props do not require a certain order
//     apiKey,
//     wallets: [injected],
//     chains: [
//       {
//         id: '0x1',
//         token: 'ETH',
//         label: 'Ethereum Mainnet',
//         rpcUrl
//       },
//       {
//         id: 42161,
//         token: 'ARB-ETH',
//         label: 'Arbitrum One',
//         rpcUrl: 'https://rpc.ankr.com/arbitrum'
//       },
//       {
//         id: '0xa4ba',
//         token: 'ARB',
//         label: 'Arbitrum Nova',
//         rpcUrl: 'https://nova.arbitrum.io/rpc'
//       },
//       {
//         id: '0x2105',
//         token: 'ETH',
//         label: 'Base',
//         rpcUrl: 'https://mainnet.base.org'
//       }
//     ]
//   })
  
// const ConnectButton = () => {
//     const [connectedAccount, setConnectedAccount] = useState(localStorage.getItem('connectedAccount') || "");
//     const [isConnected, setIsConnected] = useState(!!localStorage.getItem('connectedAccount'));
//     let web3;
//     const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
//     let ethersProvider
//     if (wallet) {
//     ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
//   }


//     useEffect(() => {
//         const loadWeb3 = async () => {
//             // Check if MetaMask is installed and accessible
//             if (window.ethereum) {
//                 // Use Web3 provider from MetaMask
//                 web3 = new Web3(window.ethereum);
//                 // Request account access if needed
//                 await window.ethereum.request({ method: 'eth_requestAccounts' });
//                 // Get the connected account
//                 const accounts = await web3.eth.getAccounts();
//                 // Update state
//                 setIsConnected(true);
//                 setConnectedAccount(accounts[0]);
//                 // Store connected account in local storage
//                 localStorage.setItem('connectedAccount', accounts[0]);
//             } else {
//                 console.log("MetaMask not installed");
//             }
//         };

//         loadWeb3();

//         return () => {
//             // Clean up
//             if (window.ethereum) {
//                 window.ethereum.removeAllListeners();
//             }
//         };
//     }, []);

//     const connectWeb3Wallet = async () => {
//         try {
//             if (window.ethereum) {
//                 // await window.ethereum.request({ method: 'eth_requestAccounts' });
//                 // const accounts = await web3.eth.getAccounts(); // Use web3 instance
//                 const accounts = await window.ethereum.request({
//                     method: "eth_accounts",
//                 });
//                 // const account = accounts[0];
//                 setIsConnected(true);
//                 setConnectedAccount(accounts[0]);
//                 localStorage.setItem('connectedAccount', accounts[0]);
//             } else {
//                 console.log("MetaMask not installed");
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     const disconnectWeb3Wallet = () => {
//         setIsConnected(false);
//         setConnectedAccount("");
//         localStorage.removeItem('connectedAccount');
//     };

//     return (
//         <div>
//             {isConnected ? (
//                 <div className="app-wrapper w-100" >
//                     <button className="connect-button1 ms-1"
//                         style={{ color: "rgba(64, 252, 254, 1)", fontWeight: "500" }}

//                         onClick={connectWeb3Wallet}>
//                         {connectedAccount
//                             ? `${connectedAccount.substring(0, 4)}...${connectedAccount.substring(38)}`
//                             : "Connect Wallet"}
//                     </button>
//                     <button
//                         className="connect-button1 ms-1"
//                         style={{ color: "rgba(64, 252, 254, 1)", fontWeight: "500" }}
//                         onClick={disconnectWeb3Wallet}
//                     >
//                         Log Out
//                     </button>
//                 </div>
//             ) : (
//                 <div >
//                     <button className="connect-button1 "
//                         style={{ marginLeft: 20, color: "rgba(64, 252, 254, 1)", fontWeight: "500" }}
//                         onClick={connectWeb3Wallet}>
//                         Connect Wallet
//                     </button>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default ConnectButton;
import React, { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import Context from "../../Context/Context";
import { useNavigate } from "react-router-dom";
let ethersProvider;

const ConnectButton = () => {
  const [connectedAccount, setConnectedAccount] = useState(
    localStorage.getItem("connectedAccount") || ""
  );
  const [isConnected, setIsConnected] = useState(
    !!localStorage.getItem("connectedAccount")
  );
  const { wallet, connect, disconnect } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    const stayConnected = async () => {
      const address = localStorage.getItem("connectedAccount");

      if (!wallet && address) {
        try {
          await connect();
          const signer = ethersProvider.getSigner();
          const account = await signer.getAddress();
          setConnectedAccount(account);
          setIsConnected(true);
          localStorage.setItem("connectedAccount", account);
        } catch (error) {
          console.log("Error connecting to wallet:", error);
        }
      }
    };

    stayConnected();
  }, []);

  useEffect(() => {
    const updateEthersProvider = async () => {
      try {
        if (wallet && wallet.provider) {
          ethersProvider = new ethers.providers.Web3Provider(wallet.provider, "any");
        } else {
          await disconnectWeb3Wallet();
          ethersProvider = null;
        }
      } catch (error) {
        console.error("Error updating ethers provider:", error);
      }
    };

    updateEthersProvider();

    return () => {
      ethersProvider = null;
    };
  }, [wallet]);

  useEffect(() => {
    const handleAccountsChanged = async () => {
      try {
        if (ethersProvider) {
          const signer = ethersProvider.getSigner();
          const account = await signer.getAddress();
          setConnectedAccount(account);
          localStorage.setItem("connectedAccount", account);
        } else {
          await disconnectWeb3Wallet();
        }
      } catch (error) {
        console.error("Error handling account change:", error);
      }
    };

    if (ethersProvider) {
      ethersProvider.provider.on("accountsChanged", handleAccountsChanged);
    }

    return () => {
      if (ethersProvider) {
        ethersProvider.provider.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      }
    };
  }, [ethersProvider]);

  const connectWeb3Wallet = async () => {
    try {
      const wallets = await connect(); // Trigger Blocknative wallet connection
      if (wallets[0]) {
        ethersProvider = new ethers.providers.Web3Provider(wallets[0].provider, "any");
        const signer = ethersProvider.getSigner();
        const account = await signer.getAddress();
        setConnectedAccount(account);
        setIsConnected(true);
        localStorage.setItem("connectedAccount", account);
      }
    } catch (error) {
      alert("Error connecting wallet:", error);
    }
  };
  useEffect(() => {
    const fetchAccount = async () => {
      try {
        if (ethersProvider) {
          const signer = ethersProvider.getSigner();
          const account = await signer.getAddress();
          if (account) {
            setConnectedAccount(account); // Make sure it's a string
            setIsConnected(true);
            localStorage.setItem('connectedAccount', account);
          }
        }
      } catch (error) {
        console.error("Error fetching connected account:", error);
      }
    };
  
    fetchAccount();
  }, [ethersProvider]);
  
  const disconnectWeb3Wallet = async () => {
    try {
      if (wallet) {
        await disconnect(wallet);
      }
      setIsConnected(false);
      setConnectedAccount("");
      localStorage.removeItem("connectedAccount");
      localStorage.clear();
      ethersProvider = null;
    } catch (error) {
      console.log("Failed to disconnect wallet:", error);
    }
  };

  const handleShowInfo = async () => {
    try {
      const signer = ethersProvider.getSigner();
      const account = await signer.getAddress();
      console.log("Account:", account);
    } catch (error) {
      console.error("Error fetching account info:", error);
    }
  };

  return (
    <div>
      {isConnected ? (
        <div className="app-wrapper w-100" style={{ display: "flex" }}>
          <button
            className="connect-button1 ms-1"
            style={{ color: "rgba(64, 252, 254, 1)", fontWeight: "500" }}
            onClick={wallet ? handleShowInfo : connectWeb3Wallet}
          >
            {connectedAccount
              ? `${connectedAccount.substring(0, 4)}...${connectedAccount.substring(38)}`
              : "Connect Wallet"}
          </button>
          <button
            className="connect-button1 ms-1"
            style={{ color: "rgba(64, 252, 254, 1)", fontWeight: "500" }}
            onClick={disconnectWeb3Wallet}
          >
            Log Out
          </button>
        </div>
      ) : (
        <div>
          <button
            className="connect-button1"
            style={{ marginLeft: 20, color: "rgba(64, 252, 254, 1)", fontWeight: "500" }}
            onClick={connectWeb3Wallet}
          >
            Connect Wallet
          </button>
        </div>
      )}
    </div>
  );
};

export { ethersProvider };
export default ConnectButton;
