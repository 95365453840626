import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { Table, Pagination } from 'react-bootstrap';
import { APIGETTOKENDATA, getCurrentChainId } from '../ContractAction/ContractDependency';
import {storeChainTokenTableListDetails} from '../ContractAction/TrendswapAction';
let cachedAnalyticsTopTokensData = [];

const OverviewTable = () => {

    const [tokenData, setTokenData] = useState(cachedAnalyticsTopTokensData);
    const [pageSize, setPageSize] = useState(5);

    const formatValue = (value) => {
        const absValue = Math.abs(parseFloat(value));
        let formattedValue = absValue;
        if (absValue >= 1e12) {
            formattedValue = (absValue / 1e12).toFixed(2) + "T";
        } else if (absValue >= 1e9) {
            formattedValue = (absValue / 1e9).toFixed(2) + "B";
        } else if (absValue >= 1e6) {
            formattedValue = (absValue / 1e6).toFixed(2) + "M";
        } else if (absValue >= 1e3) {
            formattedValue = (absValue / 1e3).toFixed(2) + "K";
        } else {
            formattedValue = absValue.toFixed(2);
        }
        return `$${formattedValue}`;
    };

    const columns = useMemo(
        () => [
            {
                Header: () => (
                    <div style={{ textAlign: 'left', paddingLeft: '20px' }}>Name</div>
                ),
                accessor: 'name',
                Cell: ({ value }) => (
                    <div style={{
                        textAlign: 'left',
                        paddingLeft: '20px',
                        minWidth: '150px',
                    }}>
                        {value}
                    </div>
                ),
            },
            {
                Header: () => (
                    <div style={{ textAlign: 'center', fontWeight: '500' }}>Price</div>
                ),
                accessor: 'price',
                Cell: ({ value }) => (
                    <div style={{
                        textAlign: 'left',
                        fontWeight: '500',
                        minWidth: '100px',
                    }}>
                        ${value}
                    </div>
                ),
            },
            {
                Header: () => (
                    <div style={{ textAlign: 'center', fontWeight: '500' }}>24h%</div>
                ),
                accessor: 'oneDayPriceChange',
                Cell: ({ value }) => (
                    <div style={{
                        textAlign: 'left',
                        fontWeight: '500',
                        minWidth: '100px',
                    }}>
                        {value}
                    </div>
                ),
            },
            {
                Header: () => (
                    <div style={{ textAlign: 'center', fontWeight: '500' }}>24h Volume</div>
                ),
                accessor: 'oneDayVol',
                Cell: ({ value }) => (
                    <div style={{
                        textAlign: 'left',
                        fontWeight: '500',
                        minWidth: '100px',
                    }}>
                        {formatValue(value)}
                    </div>
                ),
            },
            {
                Header: () => (
                    <div style={{ textAlign: 'center', fontWeight: '500' }}>Liquidity</div>
                ),
                accessor: 'tvl',
                Cell: ({ value }) => (
                    <div style={{
                        textAlign: 'left',
                        fontWeight: '500',
                        minWidth: '100px',
                    }}>
                        {formatValue(value)}
                    </div>
                ),
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        state: { pageIndex },
        nextPage,
        previousPage,
        setPageSize: setTablePageSize,
    } = useTable(
        { columns, data: tokenData, initialState: { pageIndex: 0, pageSize } },
        useSortBy,
        usePagination
    );

    const totalRows = tokenData.length;
    const startRecordIndex = pageIndex * pageSize + 1;
    const endRecordIndex = Math.min((pageIndex + 1) * pageSize, totalRows);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const currentChainId = await getCurrentChainId();
                const TokenLiveInfoDetails = await fetch(
                    `${APIGETTOKENDATA}?chainId=${currentChainId}`
                );
                if (!TokenLiveInfoDetails.ok) {
                    console.error('TokenLiveInfoDetailsOverviewTableResponseNotOk');
                    return;
                }
                const resTokenLiveInfoDetails = await TokenLiveInfoDetails.json();
                const data = resTokenLiveInfoDetails.data;
                const sortedTokens = data.sort((a, b) => b.tvl - a.tvl);
                const topTokens = sortedTokens.length > 10 ? sortedTokens.slice(0, 10) : sortedTokens;
                cachedAnalyticsTopTokensData = topTokens;
                setTokenData(topTokens);
                console.log('OverviewTableLiveInfoDetailsOverviewTable:', topTokens);
            } catch (error) {
                console.error('TokenLiveInfoDetailsOverviewTableError:', error);
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 60000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        setTablePageSize(pageSize);
    }, [setTablePageSize, pageSize]);

  useEffect(()=>{
  const gettTopToken =async()=>{
    await storeChainTokenTableListDetails();
  }
  gettTopToken();
  },[])

    return (
        <div className="container mt-2 px-0">
            <div className="table-responsive" style={{ borderRadius: '15px' }}>
                <Table {...getTableProps()} className="table">
                    <thead className="tablestruct" style={{ color: 'rgba(155, 151, 151, 1)' }}>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className={`text-center ${column.isSorted ? 'sorted-column' : ''}`}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {column.render('Header')}
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" direction="asc" className="styled__HeaderArrow-sc-eb013908-17 ldxGag">
                                                        <line x1="12" y1="19" x2="12" y2="5"></line>
                                                        <polyline points="5 12 12 5 19 12"></polyline>
                                                    </svg>
                                                ) : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" direction="desc" className="styled__HeaderArrow-sc-eb013908-17 ldxGag">
                                                        <line x1="12" y1="5" x2="12" y2="19"></line>
                                                        <polyline points="19 12 12 19 5 12"></polyline>
                                                    </svg>
                                                )
                                            ) : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} style={{
                        background:
                            'linear-gradient(180deg, #032E50 0%, #101525 100%), linear-gradient(180deg, rgba(217, 217, 217, 0.075) 0%, rgba(217, 217, 217, 0.075) 100%)',
                    }}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className="glassmorphism text-center table-row" style={{ color: 'white' }}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            {tokenData.length > 0 && (
                <div className="d-flex justify-content-end align-items-center gap-3">
                    <div className="custom-dropdown d-flex align-items-center justify-content-center">
                        Records per page:
                        <div className="dropdown-toggle" onClick={() =>
                            document.getElementById('dropdown-menu').classList.toggle('show')
                        }
                        >
                            {pageSize}
                        </div>
                        <div id="dropdown-menu" className="dropdown-menu">
                            <div className="dropdown-item" onClick={() => {
                                setPageSize(3);
                                document.getElementById('dropdown-menu').classList.remove('show');
                            }}
                            >
                                3
                            </div>
                            <div className="dropdown-item" onClick={() => {
                                setPageSize(5);
                                document.getElementById('dropdown-menu').classList.remove('show');
                            }}
                            >
                                5
                            </div>
                            <div className="dropdown-item" onClick={() => {
                                setPageSize(10);
                                document.getElementById('dropdown-menu').classList.remove('show');
                            }}
                            >
                                10
                            </div>
                        </div>
                    </div>
                    <div className="pageCount">
                        {startRecordIndex}-{endRecordIndex} of {totalRows}
                    </div>
                    <Pagination style={{ marginBottom: '0px' }}>
                        <Pagination.Prev onClick={previousPage} disabled={pageIndex === 0}>
                            <span aria-hidden="true">&laquo;</span>
                        </Pagination.Prev>
                        <Pagination.Next onClick={nextPage} disabled={pageIndex === pageCount - 1}>
                            <span aria-hidden="true">&raquo;</span>
                        </Pagination.Next>
                    </Pagination>
                </div>
            )}
        </div>
    );

};

export default OverviewTable;