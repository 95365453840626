import {React,useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import {APILIQUIDITYLIST, getCurrentAccount} from '../ContractAction/ContractDependency'
const LiquidityReward = () => {
    const [liquidityList, setLiquidityList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;

    // Calculate total pages
    const totalPages = Math.ceil(liquidityList.length / rowsPerPage);

    // Get current page data
    const currentData = liquidityList.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };
    useEffect(() => {
              async function getLiquidityList() {
            try {
                const account = await getCurrentAccount();
                const response = await fetch(`${APILIQUIDITYLIST}/${account}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                    const data = await response.json();
                    setLiquidityList(data?.data);
                    console.log("liquidityList:", data);
           
            } catch (error) {
                console.error('Error fetching liquidity list:', error);
            }
        }
    
        getLiquidityList();
    }, []); // Empty dependency array ensures the effect runs only once
    
    return (
        <>
            <div className='container' style={{ margin: '120px auto' }}>
                <div className='col-md-7 mx-auto   p-2' style={{ background: 'linear-gradient(180deg, #032E50 0%, #101525 100%), linear-gradient(180deg, rgba(217, 217, 217, 0.075) 0%, rgba(217, 217, 217, 0.075) 100%)', borderRadius: 8 }}>
                    <p className='liquidity-provider-rewards m-0'>Liquidity provider rewards</p>
                    <p className='liquidity-providers-earn'>Liquidity providers earn a 0.25% fee on all trades proportional to their share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.</p>
                </div>
                <div className='col-md-7 mx-auto mt-4 d-flex justify-content-between px-2'>
                    <div>
                        <p className='your-liquidity'>Your Liquidity</p>
                    </div>
                    <div className='d-flex' style={{ gap: '0px 20px' }}>
                    <button className='your-liquidity' style={{border:"1px solid white",borderRadius:"10px",padding:"0 20px"}}>
                        Create a pair
                    </button>
                        <Link to='/pool=add Liquidty'><button type='button' className="add-liquidity">Add Liquidity</button></Link>
                    </div>
                </div>
                <div className="col-md-7 mx-auto mt-4 px-2">
            {liquidityList.length > 0 ? (
                <div className="table-container">
                    <table className="liquidity-table">
                        <thead>
                            <tr>
                                <th>Amount In</th>
                                <th>Amount Out</th>
                                {/* <th>Symbol In</th>
                                <th>Symbol Out</th>
                                <th>Amount USDT In</th>
                                <th>Amount USDT Out</th> */}
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.map((liquidity, index) => (
                                <tr key={index}>
                                    <td>{liquidity.amounttokenA}</td>
                                    <td>{liquidity.amounttokenB}</td>
                                    {/* <td>{liquidity.symbolIn}</td>
                                    <td>{liquidity.symbolOut}</td>
                                    <td>{liquidity.amountAUSD}</td>
                                    <td>{liquidity.amountBUSDout}</td> */}
                                    <td>{new Date(liquidity.createdAt).toLocaleString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button
                            disabled={currentPage === 1}
                            onClick={handlePreviousPage}
                        >
                            Previous
                        </button>
                        <span>
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            disabled={currentPage === totalPages}
                            onClick={handleNextPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
            ) : (
                <div className="no-liquidity-found-parent">
                    <p className="no-liquidity-found">No liquidity found</p>
                </div>
            )}
        </div>
                <div className='col-md-7 mx-auto mt-3 px-2 d-flex justify-content-center'>
                    <div className="dont-see-a">Don't see a pool you joined?</div>
                    <Link to='/pool=import Pool'>
                        <p className="import-it ms-2">Import it.</p>
                    </Link>
                </div>
            </div>
        </>
    )

}

export default LiquidityReward;