import React, { useContext, useState, useEffect, useCallback, memo } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import MyNavbar from '../Component/MyNavbar';
import pharrowimg from '../Assets/images2/diagonalarrow.png';
import phquestion from "../Assets/images2/ph_question.png";
import managearrow from '../Assets/images2/managearrow.png';
import Context from '../Context/Context';
import { getUserPairBalance, getPairTotalSupplyAndPoolSharePerc, getUserLiquidityDetails, getTokenSymbol } from '../ContractAction/TrendswapAction';

const ManagePool = memo(() => {

    const { selectedNetwork, removelqSelectedToken1Address, removelqSelectedToken2Address } = useContext(Context);
    const [symbolSelectedToken1Addr, setSymbolSelectedToken1Addr] = useState('');
    const [symbolSelectedToken2Addr, setSymbolSelectedToken2Addr] = useState('');
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [userPoolSharePerc, setUserPoolSharePerc] = useState(0);
    const [userPairBalance, setUserPairBalance] = useState(0);
    const [userTokenALiquidityAdded, setUserTokenALiquidityAdded] = useState(0);
    const [userTokenBLiquidityAdded, setUserTokenBLiquidityAdded] = useState(0);
    const toggleAccordion = () => setAccordionOpen(!accordionOpen);

    const fetchUserData = useCallback(debounce(async () => {
        if (!removelqSelectedToken1Address || !removelqSelectedToken2Address) {
            console.warn("Invalid token addresses:", removelqSelectedToken1Address, removelqSelectedToken2Address);
            resetUserData();
            return;
        }
        try {
            const [symbolSelectedToken1, symbolSelectedToken2, poolSharePerc, pairBalance, userTokenALiquidity, userTokenBLiquidity] = await Promise.all([
                getTokenSymbol(removelqSelectedToken1Address),
                getTokenSymbol(removelqSelectedToken2Address),
                getPairTotalSupplyAndPoolSharePerc(removelqSelectedToken1Address, removelqSelectedToken2Address),
                getUserPairBalance(removelqSelectedToken1Address, removelqSelectedToken2Address),
                getUserLiquidityDetails(removelqSelectedToken1Address, removelqSelectedToken2Address, removelqSelectedToken1Address),
                getUserLiquidityDetails(removelqSelectedToken1Address, removelqSelectedToken2Address, removelqSelectedToken2Address)
            ]);
            setSymbolSelectedToken1Addr(symbolSelectedToken1 || '');
            setSymbolSelectedToken2Addr(symbolSelectedToken2 || '');
            setUserPoolSharePerc(poolSharePerc[1] || 0);
            // setUserPairBalance(pairBalance / 10 ** 18);
            setUserPairBalance(Number(pairBalance) || 0);
            setUserTokenALiquidityAdded(userTokenALiquidity || 0);
            setUserTokenBLiquidityAdded(userTokenBLiquidity || 0);
        } catch (error) {
            console.error("Error fetching user data:", error);
            resetUserData();
        }
    }, 300), [removelqSelectedToken1Address, removelqSelectedToken2Address]);

    const resetUserData = () => {
        setSymbolSelectedToken1Addr('');
        setSymbolSelectedToken2Addr('');
        setUserPoolSharePerc(0);
        setUserPairBalance(0);
        setUserTokenALiquidityAdded(0);
        setUserTokenBLiquidityAdded(0);
    };

    useEffect(() => {
        fetchUserData();
        return () => {
            fetchUserData.cancel();
        };
    }, [fetchUserData]);

    return (
        <div>
            <MyNavbar selectedNetwork={selectedNetwork} />
            <div className='container' style={{ marginTop: '120px' }}>
                <div className='col-md-7 mx-auto  glassmorphism pt-1' style={{ borderRadius: '8px' }}>
                    <p className='liquidity-provider-rewards m-0'>Liquidity provider rewards</p>
                    <p className='liquidity-providers-earn'>Liquidity providers earn a 0.25% fee on all trades proportional to their share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.</p>
                </div>
                <div className='col-md-7 mx-auto mt-4 d-flex justify-content-between px-2'>
                    <div>
                        <p className='your-liquidity'>Your Liquidity</p>
                    </div>
                    <div className='d-flex' style={{ gap: '0px 20px' }}>
                        <Link to='/pool=add Liquidty'><button type='button' className="add-liquidity">Add Liquidity</button></Link>
                    </div>
                </div>
                {/* <div className='col-md-7 mx-auto mt-4 '>
                    <div className="account-analytics-and-assured-parent">
                        <div className="account-analytics-and">Account Analytics and assured fees</div>
                        <img className="pharrow-down-icon" alt="" src={pharrowimg} />
                    </div>
                </div> */}
                <div className='col-md-7 mx-auto mt-4 px-2 p-0  glassmorphism '>
                    <div className='_883856ad ' onClick={toggleAccordion}>
                        <div className='d-flex'>
                            <img alt='' src={phquestion} style={{ height: '24px', width: '24px' }} />
                            <div className='token1'>{symbolSelectedToken1Addr} /</div>
                            <img alt='' src={phquestion} style={{ height: '24px', width: '24px' }} />
                            <div className='token1'>{symbolSelectedToken2Addr}</div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='add'>Manage</div>
                            <img className={accordionOpen ? ' ms-2 rotatedarrow' : 'ms-2'} alt='' src={managearrow} />
                        </div>
                    </div>
                    {accordionOpen && (
                        <>
                            <div className='_883856ad'>
                                <div>Your pool tokens</div>
                                <div>{userPairBalance.toFixed(2)}</div>
                            </div>
                            <div className='_883856ad'>
                                <div>Pooled {symbolSelectedToken1Addr}:</div>
                                <div>{userTokenALiquidityAdded}</div>
                            </div>
                            <div className='_883856ad'>
                                <div>Pooled {symbolSelectedToken2Addr}:</div>
                                <div>{userTokenBLiquidityAdded}</div>
                            </div>
                            <div className='_883856ad'>
                                <div>Your pool share</div>
                                <div>{userPoolSharePerc.toFixed(2)}%</div>
                            </div>
                            <div className='_883856ad'>
                                <div className='account-analytics-and-assured-parent text-center ' style={{ padding: '4px 0px', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className='account-analytics-and'>Account Analytics and assured fees</div>
                                    <img className='pharrow-down-icon' alt='' src={pharrowimg} style={{ height: '20px', width: '20px', marginLeft: '5px' }} />
                                </div>
                            </div>
                            <div className='_883856ad' style={{ gap: '0px 10px' }}>
                                <Link to='/pool=add Liquidty' className='w-100 connect-button '>
                                    <button style={{ color: 'rgba(64, 252, 254, 1)' }}>Add</button>
                                </Link>
                                <Link to='/remove=liquidty' className="connect-button w-100">
                                    <button style={{ color: 'rgba(64, 252, 254, 1)' }}>Remove</button>
                                </Link>
                            </div>
                        </>
                    )}
                </div>
                <div className='col-md-7 mx-auto mt-3 px-2 d-flex justify-content-center'>
                    <div className="dont-see-a">Don't see a pool you joined?</div>
                    <Link to='/pool=import Pool'>
                        <p className="import-it ms-2">Import it.</p>
                    </Link>
                </div>
            </div>
        </div>
    );

});

export default ManagePool;